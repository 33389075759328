export default class ActionTableItem {
    actionId = 0;
    actionStatus = 0;
    menuId = 0;
    menuIdName = "";
    actionTypeId: string | number = 0;
    actionTypeIdName = "";
    actionSortId = 0;
    actionCode = "";
    actionName = "";
    actionRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
